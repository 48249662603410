import { Flex, Text } from '@chakra-ui/react';
import React from 'react';

function Testamonial({ quote, fullName, organisation }) {
  return (
    <>
      <Flex
        flexDir="column"
        justify="start"
        align="start"
        bgColor="white"
        borderRadius="2xl"
        p={8}
        border="1px solid"
        borderColor="gray.200"
      >
        <Text color="gray.800" w="100%" fontSize="md">
          "{quote}"
        </Text>
        <Text
          mt={2}
          fontSize="md"
          color="gray.800"
          w="100%"
          fontWeight="semibold"
        >
          {fullName}
        </Text>
        <Text fontSize="sm" color="gray.600" w="100%">
          {organisation}
        </Text>
      </Flex>
    </>
  );
}

export default Testamonial;
