import { ExternalLinkIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  Heading,
  Text,
  Link,
  UnorderedList,
  textDecoration,
  AspectRatio,
} from '@chakra-ui/react';
import { BLOCKS } from '@contentful/rich-text-types';
import axios from 'axios';
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import React, { useEffect, useState } from 'react';
import LinkButton from '../components/buttons/link-button';
import InHouseTraining from '../components/courses/in-house-training-form';
import Testamonial from '../components/courses/testamonial';
import UpcomingCourse from '../components/courses/upcoming-course';
import SEO from '../components/seo';
// @ts-ignore
import NothingFound from '../images/nothing-found.svg';
import { capitalize } from '../utils/string-functions';

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_node, children) => (
      <Text mt={3} color="gray.600">
        {children}
      </Text>
    ),
    [BLOCKS.UL_LIST]: (node, children) => (
      <UnorderedList>{children}</UnorderedList>
    ),
  },
};

function CoursePage({
  pageContext: {
    courseEvidenceBasesUrl,
    courseCategory,
    courseCurriculumUrl,
    courseImage,
    courseName,
    longDescription,
    arloTag,
    testimonials,
    forParents,
    shopLink,
    videoUrl,
    eoiFormLink,
    // enquireAtEmail,
  },
}) {
  console.log('EOI FORM LINK', eoiFormLink);
  const image = getImage(courseImage);
  const richTextBody = renderRichText(longDescription, options);
  const [upcomingCourses, setUpcomingCourses] = useState([]);
  const categoryList = courseCategory.filter(
    (courseCategory) =>
      courseCategory.includes('secondary') || courseCategory.includes('primary')
  );

  const handleDownloadCourseCurriculum = () => {};

  const handleDownloadEvidenceBases = () => {};

  useEffect(() => {
    async function getUpcomingCourses() {
      const fetchedCourses = (await axios.get(
        `https://parentshop.arlo.co/api/2012-02-01/pub/resources/eventsearch/?fields=Name,Description,EventID,Sessions,Location,ViewUri,PlacesRemaining,Presenters,RegistrationInfo,StartDateTime,EndDateTime,IsFull,Categories,Tags,AdvertisedOffers,RegistrationInfo&top=200`
      )) as any;
      const filteredCourses = fetchedCourses.data.Items?.filter((course) => {
        return course?.Tags?.includes(arloTag);
      });

      const futureCourses = filteredCourses.filter(
        (course) => new Date(course?.StartDateTime) > new Date()
      );

      console.log('futureCourses', futureCourses);
      setUpcomingCourses(futureCourses);
    }
    getUpcomingCourses();
  }, []);

  return (
    <>
      <SEO title={`${courseName}`} />
      <Flex flexDir="column" justify="start" align="center" w="100%" mb={16}>
        <Flex my={{ base: 4, lg: 8 }} w="100%" flexDir="column">
          <LinkButton
            text="Back to courses"
            link={forParents ? 'for-parents' : 'for-professionals'}
            iconPosition="left"
          />
          <Divider mt={4} w="100%" />
        </Flex>
        <Flex
          justifyContent="space-between"
          alignItems="flex-start"
          w="100%"
          flexDir={{ base: 'column', lg: 'row' }}
        >
          <Flex
            justify="between"
            align="start"
            flexDir="column"
            w={{ base: '100%', lg: '50%' }}
            flexGrow={1}
            order={{ base: 2, lg: 1 }}
          >
            <Flex
              flexDir="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              mr={{ base: 0, lg: 20 }}
              flexGrow={1}
              mt={{ base: 2, lg: 0 }}
            >
              <Text
                fontWeight="semibold"
                textTransform="uppercase"
                letterSpacing=".2rem"
                color="primary.500"
                fontSize="sm"
                mt={7}
              >
                {courseCategory.map((category) => (
                  <span>{category + ' '}</span>
                ))}
              </Text>
              <Heading as="h1" mt={3}>
                {capitalize(courseName)}
              </Heading>
              <Text>{richTextBody}</Text>
              <Text mt={3} display="flex" alignItems={'center'} gap={2}>
                For more information, please email{' '}
                <Link
                  href={`mailto:communications@parentshop.com.au`}
                  textDecoration="underline"
                >
                  communications@parentshop.com.au
                </Link>
              </Text>
            </Flex>
            {videoUrl && (
              <AspectRatio
                width="400px"
                ratio={1.6}
                mt={6}
                mr={{ base: 0, lg: 20 }}
              >
                <iframe
                  src={videoUrl}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title={courseName}
                />
              </AspectRatio>
            )}
            <Flex
              flexDir={{ base: 'column', md: 'row' }}
              w="100%"
              justify={{ base: 'space-between', lg: 'start' }}
              align="center"
              mt={8}
            >
              {courseCurriculumUrl && (
                <Link
                  href={courseCurriculumUrl}
                  download
                  target="_blank"
                  _hover={{ textDecoration: 'none' }}
                  w={{ base: '100%', lg: '40%' }}
                  mr={{ base: 0, md: 4 }}
                >
                  <Button
                    colorScheme="primary"
                    variant="outline"
                    onClick={handleDownloadCourseCurriculum}
                    w="100%"
                  >
                    Download curriculum
                  </Button>
                </Link>
              )}
              {eoiFormLink && (
                <Link
                  href={eoiFormLink}
                  download
                  target="_blank"
                  _hover={{ textDecoration: 'none' }}
                  w={{ base: '100%', lg: '40%' }}
                  mr={{ base: 0, md: 4 }}
                >
                  <Button
                    colorScheme="secondary"
                    variant="link"
                    onClick={handleDownloadCourseCurriculum}
                    w="100%"
                    textDecoration={'underline'}
                    display={'flex'}
                    alignItems={'center'}
                    gap={2}
                  >
                    Register your interest
                    <ExternalLinkIcon />
                  </Button>
                </Link>
              )}
              {courseEvidenceBasesUrl && (
                <Link
                  href={courseEvidenceBasesUrl}
                  download
                  target="_blank"
                  _hover={{ textDecoration: 'none' }}
                  w={{ base: '100%', lg: '40%' }}
                  mr={{ base: 0, md: 4 }}
                  mt={{ base: 4, md: 0 }}
                >
                  <Button
                    colorScheme="primary"
                    variant="outline"
                    onClick={handleDownloadEvidenceBases}
                    w="100%"
                  >
                    Download evidence bases
                  </Button>
                </Link>
              )}
              {shopLink && (
                <Link
                  href={shopLink}
                  _hover={{ textDecoration: 'none' }}
                  w={{ base: '100%', lg: '40%' }}
                  mt={{ base: 4, md: 0 }}
                >
                  <Button
                    colorScheme="primary"
                    variant="outline"
                    onClick={handleDownloadEvidenceBases}
                    w="100%"
                  >
                    Buy online course
                  </Button>
                </Link>
              )}
            </Flex>
          </Flex>
          <Box
            w={{ base: '100%', lg: '50%' }}
            overflow="hidden"
            // boxShadow="lg"
            borderRadius="lg"
            mt={{ base: 0, lg: 10 }}
            order={{ base: 1, lg: 2 }}
          >
            <Img
              image={image}
              alt=""
              imgStyle={{
                objectFit: 'cover',
                objectPosition: 'center',
              }}
            />
          </Box>
        </Flex>
        {upcomingCourses.length > 0 && (
          <Flex flexDir="column" justify="start" align="start" mt={16} w="100%">
            <Heading
              as="h2"
              w="100%"
              pb={4}
              borderBottom="1px solid"
              borderColor="gray.200"
            >
              Upcoming courses
            </Heading>

            <Grid
              gridTemplateColumns={{
                base: '1fr%',
                md: 'repeat(2, 1fr)',
                lg: 'repeat(4, 1fr)',
              }}
              gap={6}
              mt={8}
            >
              {upcomingCourses?.map((course, index) => {
                return (
                  <UpcomingCourse
                    key={course.StartDateTime}
                    name={course.Name}
                    location={course.Location.Name}
                    sessions={course.Sessions}
                    image={image}
                    price={
                      course.AdvertisedOffers[0].OfferAmount
                        .FormattedAmountTaxInclusive
                    }
                    isFull={course.isFull}
                    refresher={course.Tags.includes('refresher')}
                    registrationUrl={course.RegistrationInfo.RegisterUri}
                  />
                );
              })}
            </Grid>
          </Flex>
        )}
        {testimonials.length > 0 && (
          <Flex
            flexDir="column"
            justify="start"
            align="start"
            my={{ base: 8 }}
            w="100%"
          >
            <Heading as="h3">Testimonials</Heading>
            <Grid
              gridTemplateColumns={{ base: '1fr', lg: 'repeat(3, 1fr)' }}
              gap={{ base: 4, lg: 8 }}
              mt={6}
            >
              {testimonials.map((testimonial) => (
                <Testamonial
                  quote={testimonial.node.quote.internal.content}
                  fullName={testimonial.node.fullName}
                  organisation={testimonial.node.organisation}
                />
              ))}
            </Grid>
          </Flex>
        )}
        {!forParents && <InHouseTraining />}
      </Flex>
    </>
  );
}

export default CoursePage;
