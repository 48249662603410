import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image';
import { Flex, Box, Heading, Text } from '@chakra-ui/react';
import LinkButton from '../buttons/link-button';

function InHouseTraining() {
  const data = useStaticQuery(graphql`
    query {
      inhouse: allImageSharp(
        filter: { original: { src: { regex: "/about-parentshop/" } } }
      ) {
        nodes {
          gatsbyImageData(width: 600)
        }
      }
    }
  `);
  return (
    <Link to="/professionals/in-house-training">
      <Flex
        flexDir={{ base: 'column', lg: 'row' }}
        w="100%"
        bg="white"
        borderRadius="3xl"
        boxShadow="md"
        mt={8}
        overflow="hidden"
        h={{ base: '100%', lg: '20rem' }}
        transition="all .1s ease-out"
        cursor="pointer"
        _hover={{ boxShadow: 'xl', transform: 'translateY(-5px)' }}
        flexGrow={1}
      >
        <Box w={{ base: '100%', lg: '50%' }} overflow="hidden">
          <Img image={getImage(data.inhouse.nodes[0].gatsbyImageData)} alt="in-house"/>
        </Box>
        <Flex
          flexDir="column"
          alignItems="start"
          justifyContent="center"
          p={{ base: 8, xl: 12 }}
          w={{ base: '100%', lg: '50%' }}
        >
          <Heading as="h3" size="lg">
            We can also train your team in-house!
          </Heading>
          <Text my={4} color="gray.600">
            Parentshop offers convenient, flexible and highly cost-effective
            in-house training. Equip your whole school with a unified approach
            to managing anxiety and building resilience.
          </Text>
          <LinkButton
            text="Learn more"
            link="professionals/in-house-training"
          />
        </Flex>
      </Flex>
    </Link>
  );
}

export default InHouseTraining;
