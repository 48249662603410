import { Box, Button, chakra, Flex, Heading, Text } from '@chakra-ui/react';
import { format } from 'date-fns';
import { GatsbyImage as Img } from 'gatsby-plugin-image';
import React from 'react';

function UpcomingCourse({
  name,
  location,
  sessions,
  image,
  price,
  isFull,
  refresher = false,
  registrationUrl,
}) {
  return (
    <Flex
      flexDir="column"
      bgColor="white"
      boxShadow="md"
      _hover={{ boxShadow: 'lg', transform: 'translateY(-5px)' }}
      borderRadius="xl"
      transition=".1s ease-in all"
      cursor="pointer"
      w="100%"
      alignItems="start"
      // overflow="hidden"
      onClick={() => window.open(registrationUrl, '_blank')}
      h="100%"
    >
      <Box
        w="100%"
        height="15rem"
        overflow="hidden"
        position="relative"
        borderTopLeftRadius="xl"
        borderTopRightRadius="xl"
      >
        <Img
          image={image}
          style={{ height: '100%', width: '100%' }}
          imgStyle={{ objectFit: 'cover' }}
        />
        <Text
          position="absolute"
          top={5}
          right={5}
          bgColor="primary.500"
          px={3}
          py={0.5}
          borderRadius="2xl"
          fontWeight="semibold"
          fontSize="md"
          color="white"
        >
          {isFull ? 'Sold out' : price}
        </Text>
      </Box>
      <Flex flexDir="column" w="100%" alignItems="start" p={5} flexGrow={1}>
        <Flex flexDir="column" flexGrow={1} alignItems="start" mb={2}>
          <Text
            px={3}
            py={1}
            bgColor="gray.100"
            borderRadius="2xl"
            color="gray.800"
            fontWeight="semibold"
            w="auto"
            fontSize="sm"
          >
            {location}
          </Text>
          <Heading size="md" mt={3} mb={2}>
            {name && refresher ? `${name} (Refresher)` : name}
          </Heading>
          <Text fontSize="sm" color="gray.600">
            Date(s):
          </Text>
          {sessions.map((session) => (
            <>
              <Text mt={2} mb={0} fontWeight="semibold" fontSize="sm">
                {format(new Date(session.StartDateTime), 'do MMMM, yyyy')}
              </Text>
              <Text mt={0} mb={2} fontWeight="normal" fontSize="sm">
                {format(new Date(session.StartDateTime), 'h:mm aaa - ') +
                  format(new Date(session.EndDateTime), 'h:mm aaa')}
              </Text>
            </>
          ))}
        </Flex>
        <Button
          w="100%"
          colorScheme={isFull ? 'gray' : 'secondary'}
          disabled={isFull}
          flexGrow={0}
        >
          Register now
        </Button>
      </Flex>
    </Flex>
  );
}

export default UpcomingCourse;
